<script setup>
import { onMounted } from "vue";
import mushroom from "cem-primary-api";
import Layout from "../../layouts/main.vue";
import { useRouter, useRoute } from "vue-router";
import { reactive } from "vue";
import { ref } from "vue";
import Loading from "../../components/loading.vue";
import toastr from "toastr";
import LanguageService from "../../service/LanguageService";
import { langWeb } from "../../stores/lang";
const lang_web = langWeb();
const lang = ref(lang_web.showLang.toLocaleUpperCase());
const loading = ref(true);
const router = useRouter();
const route = useRoute();
const guide = reactive({
  guideName: "",
  urlFile: "",
});

const getCurrentGuide = async () => {
  try {
    const response = await mushroom.user_manual.findByIdAsync({
      id: route.params.id, // required
    });
    if (response.result) {
      guide.urlFile = createUrl(response.result);
      guide.guideName = response.result?.title;
      loading.value = false;
    }
  } catch (e) {
    guide.urlFile = null;
    console.error("Có lỗi: %o", e);
    toastr.error(LanguageService[lang.value].t_loading_guide_failed);
    loading.value = false;
  }
};
const createUrl = (instructionData) => {
  if (instructionData) {
    let urlFile = mushroom.$file.linkBuilder.thumb
      .id(instructionData.file_id)
      .build();
    return urlFile;
  }
};
onMounted(() => {
  getCurrentGuide();
});
</script>
<template>
  <Layout>
    <div class="card" style="min-height: 100vh">
      <div class="card-header">
        <h3
          v-show="guide.guideName"
          class="text-uppercase mb-0 d-flex align-items-center justify-content-start"
          style="width: 100%;"
        >
          <button
            v-on:click="router.go(-1)"
            class="d-flex align-items-center me-3"
            style="border: none; background-color: transparent; padding: 0"
          >
            <i class="ri-arrow-left-line"></i>
          </button>
          <div class="guideTitle">
            {{ guide.guideName }}
          </div>
        </h3>
      </div>
      <div class="card-body">
        <div class="main-body">
          <div v-if="loading">
            <Loading></Loading>
          </div>
          <iframe
            v-else-if="guide.urlFile && !loading"
            :src="guide.urlFile"
            style="width: 100%; height: 100vh"
          ></iframe>
          <div v-else></div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<style lang="scss" scoped >
.guideTitle {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}
</style>
